import { Link, Outlet } from "react-router-dom";
import gardenlogo from "../Assets/Homepics/Garden-Residences-logo.png"
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { useState, Fragment } from "react";
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import { HiOutlineBars3 } from "react-icons/hi2";
import { StickyNavigationGarden } from "../Hooks/stickyNavigationGarden";
import { HandleGardenScrollUp } from "../Functions/GardenScrollUp";
import { GardenNavContents } from "../Utility/Objects/GardenNavContents";
import { GardenPopOver } from "../Utility/Objects/GardenPopOver";
import { FaXmark } from "react-icons/fa6";
import { SlArrowDown } from "react-icons/sl";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const { isSticky } = StickyNavigationGarden();



  return (

    <header className={`w-full ${isSticky ? 'bg-white shadow-lg ' : ''} transition-all fixed duration-400 ease-in-out  top-0 z-50`}>
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 md:px-8" aria-label="Global">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a href="/">
            <img className="lg:h-[70px] lg:pl-[10px] lg:-mt-[40px] lg:-mb-[100px] sm:-mb-[100px] sm:-mt-[20px] sm:h-[50px] sm:-ml-[40px] sm:pr-[50px] md:-ml-[20px] md:h-[70px] md:-mb-[100px] sd:-ml-[40px] sd:h-[80px] w-auto drop-shadow-lg" src={gardenlogo} alt="" loading="lazy" />
          </a>
          <div className="lg:ml-[400px] -mb-24 ml-[870px] mx-auto hidden w-full xl:block md:w-auto" id="navbar-default">
            <ul className="lg:-mt-[62px] font-medium flex flex-col p-4 md:p-0 border border-gray-100 rounded-lg  md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0  dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <a href="/" className="block py-2 px-3 text-black rounded md:bg-transparent md:p-0 dark:text-black hover:text-gray-700" aria-current="page">Home</a>
              </li>
              <li className="relative ">
                <button
                  id="dropdownButton"
                  onClick={toggleMenu}
                  className="block py-2 px-4 text-black rounded md:bg-transparent md:p-0 dark:text-white md:hover:text-gray-700 md:dark:text-blue-500"
                  aria-expanded={isOpen}
                >
                  Rooms
                  <ChevronDownIcon className="ml-2 w-5 h-5" aria-hidden="true" />
                </button>
                <ul
                  id="dropdownMenu"
                  className={`absolute mt-2 bg-gray-800 text-white rounded-lg border border-gray-700 ${isOpen ? 'block' : 'hidden'}`}
                >
                  <li>
                    <Link to="/rooms" className="block py-2 px-4 hover:bg-gray-700 whitespace-nowrap">
                      Rooms
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/standard-studio"
                      className="block py-2 px-4 hover:bg-gray-700 whitespace-nowrap"
                    >
                      Standard Studio
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/grand-studio"
                      className="block py-2 px-4 hover:bg-gray-700 whitespace-nowrap"
                    >
                      Grand Studio
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/1-bedroom-unit"
                      className="block py-2 px-4 hover:bg-gray-700 whitespace-nowrap"
                    >
                      1 Bedroom Unit
                    </Link>
                  </li>
                </ul>
              </li>
              {/* <li>
                <a href="/360-tour-lobby" className="block py-2 px-3 text-black rounded md:bg-transparent md:p-0 dark:text-black hover:text-gray-700" aria-current="page">360 Tour</a>
              </li>*/}
            </ul>
          </div>

          <div className={`flex xl:hidden sm:ml-[50px] md:ml-[670px] md:-mb-[10px] ${mobileMenuOpen ? "hidden" : ""}`}>
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 "
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <HiOutlineBars3 className="lg:ml-[230px] lg:h-8 w-12 text-lagrand-greenlight" />
            </button>
          </div>

          <Dialog as="div" className="xl:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <div className="fixed inset-0 z-10" />
            <Dialog.Panel className="fixed pt-10 inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <div className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img
                    className="h-8 w-auto"
                    src=""
                    alt=""
                  />
                </div>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <FaXmark className="h-6 w-6 " aria-hidden="true" />
                </button>
              </div>
              <div className=" flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    {GardenNavContents.map((item, key) => (
                      <Disclosure as="div" className="-mx-3" key={item.name}>
                        {({ open }) => (
                          <>
                            {key === 0 || key === 6 ?
                              <Link to={item.href} onClick={() => { setMobileMenuOpen(false); HandleGardenScrollUp() }} className="flex mt-5 hover:text-black hover:underline w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                {item.name}
                              </Link> :
                              <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                {item.name}
                                <SlArrowDown className={classNames(open ? 'rotate-180' : '', 'h-3 w-3 flex-none')} aria-hidden="true" />
                              </Disclosure.Button>}
                            <Disclosure.Panel className="mt-2 space-y-2">
                              {GardenPopOver[key].map((item) => (
                                <Link
                                  key={item.name}
                                  onClick={() => {
                                    setMobileMenuOpen(false)
                                    HandleGardenScrollUp()
                                  }}
                                  to={item.href}
                                  className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold hover:text-black hover:underline leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                  {item.name}
                                </Link>
                              ))}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>


        </div>
      </nav>

      <Outlet />
    </header>
  )
}